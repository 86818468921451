<template>
  <b-container fluid>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h4 class="mr-2">New Inventory Item</h4>
      </div>
      <div class="d-flex align-items-center">
        <b-button variant="dark" class="mr-2 my-1" @click="saveAsDraft">
          Save as Draft
        </b-button>
        <b-button variant="primary" @click="submitForm"> Save </b-button>
      </div>
    </div>
    <ValidationObserver ref="formObserver">
      <b-card>
        <b-row>
          <!-- First Column -->
          <b-col cols="6">
            <!-- Product Name -->
            <ValidationProvider
              name="Product Name"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Product Name">
                <b-form-input
                  v-model="productName"
                  placeholder="Enter Product Name"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Select Product Category -->
            <ValidationProvider
              name="Product Category"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Select Product Category">
                <v-select
                  v-model="product_category_id"
                  :options="productCategories"
                  label="category_name"
                  :reduce="(category) => category.id"
                  placeholder="Select a Product Category"
                  class="v-select"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Selling Price -->
            <ValidationProvider
              name="Selling Price"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Selling Price">
                <b-form-input
                  v-model="sellingPrice"
                  placeholder="Selling Price"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Cost Price -->
            <ValidationProvider
              name="Cost Price"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Cost Price">
                <b-form-input
                  v-model="costPrice"
                  placeholder="Cost Price"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Quantity in Stock -->
            <ValidationProvider
              name="Quantity in Stock"
              rules="required|integer"
              v-slot="{ errors }"
            >
              <b-form-group label="Quantity in Stock">
                <b-form-input
                  v-model="quantityInStock"
                  type="number"
                  placeholder="Enter Quantity in Stock"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Warehouse Location -->
            <ValidationProvider
              name="Warehouse Location"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Warehouse Location">
                <v-select
                  v-model="warehouse_id"
                  :options="branches"
                  label="warehouse"
                  placeholder="Select a Warehouse Location"
                  class="v-select"
                  multiple
                  :reduce="(branch) => branch.id"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Discount -->
            <b-form-group label="Discount">
              <b-row>
                <b-col cols="9">
                  <b-form-input
                    v-model="discount"
                    placeholder="Enter Discount"
                    :disabled="!addDiscount"
                  ></b-form-input>
                </b-col>
                <b-col cols="3" class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="addDiscount"
                    switch
                  ></b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>

            <!-- Expiry Date -->
            <b-form-group label="Expiry Date">
              <b-row>
                <b-col cols="9">
                  <flat-pickr
                    v-model="expiryDate"
                    class="form-control"
                    placeholder="Select Expiry Date"
                    :disabled="!addExpiryDate"
                  ></flat-pickr>
                </b-col>
                <b-col cols="3" class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="addExpiryDate"
                    switch
                  ></b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <!-- Second Column -->
          <b-col cols="6">
            <!-- Short Description -->
            <b-form-group label="Short Description">
              <b-form-input
                v-model="shortDescription"
                placeholder="Enter Short Description"
              ></b-form-input>
            </b-form-group>

            <!-- Product Long Description -->
            <b-form-group label="Product Long Description">
              <b-form-textarea
                v-model="longDescription"
                placeholder="Enter Product Long Description"
              ></b-form-textarea>
            </b-form-group>

            <!-- Return Policy -->
            <b-form-group label="Return Policy">
              <b-row>
                <b-col cols="9">
                  <b-form-textarea
                    v-model="returnPolicy"
                    placeholder="Enter Return Policy"
                    :disabled="!addReturnPolicy"
                  ></b-form-textarea>
                </b-col>
                <b-col cols="3" class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="addReturnPolicy"
                    switch
                  ></b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>

            <!-- Date Added -->
            <b-form-group label="Date Added">
              <flat-pickr
                v-model="dateAdded"
                class="form-control"
                placeholder="Select Date and Time"
              ></flat-pickr>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </ValidationObserver>
    <b-card>
      <b-form-group label="Upload Images">
        <b-form-file
          v-model="images"
          accept="image/*"
          multiple
          placeholder="Choose images..."
          drop-placeholder="Drop images here..."
          @change="onImageChange"
        ></b-form-file>
      </b-form-group>
      <div v-if="imageUrls.length" class="d-flex flex-wrap">
        <div
          v-for="(image, index) in imageUrls"
          :key="index"
          class="position-relative m-2"
          style="width: 300px; height: 300px"
        >
          <b-img
            :src="image.url"
            fluid
            alt="Uploaded Image"
            style="width: 100%; height: 100%; object-fit: cover"
          ></b-img>
          <div
            class="position-absolute"
            style="
              top: 5px;
              right: 5px;
              background-color: white;
              border-radius: 50%;
            "
          >
            <b-button
              variant="danger"
              class="p-1"
              style="
                font-size: 0.75rem;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
              @click="deleteImage(index)"
            >
              <span style="font-size: 1rem">&times;</span>
            </b-button>
          </div>
        </div>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, numeric, integer } from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    MyTable,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    FeatherIcon,
  },

  data() {
    return {
      productName: "",
      product_category_id: null, // Initialize to null
      productCategories: [], // Populate this with your categories
      branches: [],
      sellingPrice: "",
      costPrice: "",
      quantityInStock: "",
      warehouse_id: "",
      discount: "",
      addDiscount: false,
      expiryDate: null,
      addExpiryDate: false,
      shortDescription: "",
      longDescription: "",
      returnPolicy: "",
      addReturnPolicy: false,
      dateAdded: null,
      images: [],
      imageUrls: [],
      required,
      numeric,
      integer,
    };
  },

  created() {
    this.fetchProductCategories();
    this.fetchBranches();
    this.loadDraft();
  },

  methods: {
    onImageChange(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const url = URL.createObjectURL(file);
        this.imageUrls.push({ url, file });
      }
    },
    deleteImage(index) {
      this.imageUrls.splice(index, 1);
      this.images.splice(index, 1);
    },
    async fetchProductCategories() {
      try {
        const response = await this.$http.get(
          "inventory/admin/get_product_categories"
        );
        if (response.status === 200 && response.data.data?.length > 0) {
          this.productCategories = response.data.data;
        } else {
          this.productCategories = [];
        }
      } catch (error) {
        console.error("Error fetching product categories:", error);
        this.productCategories = [];
      }
    },

    async fetchBranches() {
      try {
        const response = await this.$http.get(
          "inventory/admin/get_all_warehouses"
        );
        if (response.status === 200 && response.data.data?.length > 0) {
          this.branches = response.data.data;
        } else {
          this.branches = [];
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
        this.branches = [];
      }
    },

    loadDraft() {
      const draft = localStorage.getItem("addProductDraft");
      if (draft) {
        const parsedDraft = JSON.parse(draft);
        this.productName = parsedDraft.productName;
        this.product_category_id = parsedDraft.product_category_id;
        this.sellingPrice = parsedDraft.sellingPrice;
        this.costPrice = parsedDraft.costPrice;
        this.quantityInStock = parsedDraft.quantityInStock;
        this.warehouse_id = parsedDraft.warehouse_id;
        this.discount = parsedDraft.discount;
        this.addDiscount = parsedDraft.addDiscount;
        this.expiryDate = parsedDraft.expiryDate;
        this.addExpiryDate = parsedDraft.addExpiryDate;
        this.shortDescription = parsedDraft.shortDescription;
        this.longDescription = parsedDraft.longDescription;
        this.returnPolicy = parsedDraft.returnPolicy;
        this.addReturnPolicy = parsedDraft.addReturnPolicy;
        this.dateAdded = parsedDraft.dateAdded;
        this.uploadedImages = parsedDraft.uploadedImages;
      }
    },

    async submitForm() {
      const isValid = await this.$refs.formObserver.validate();
      if (!isValid) {
        return;
      }

      const formData = new FormData();
      formData.append("product_category_id", this.product_category_id);
      formData.append("product_name", this.productName);
      formData.append("quantity", this.quantityInStock);
      formData.append("short_description", this.shortDescription);
      formData.append("long_description", this.longDescription);
      formData.append("selling_price", this.sellingPrice);
      formData.append("cost_price", this.costPrice);
      formData.append("date_added", this.dateAdded);
      formData.append("warehouse_id", this.warehouse_id);

      if (this.addDiscount) {
        formData.append("discount", this.discount);
      }

      if (this.addExpiryDate) {
        formData.append("expiry_date", this.expiryDate);
      }

      if (this.addReturnPolicy) {
        formData.append("return_policy", this.returnPolicy);
      }

      this.imageUrls.forEach((image, index) => {
        formData.append(`product_images[${index}]`, image.file);
      });

      try {
        const response = await this.$http.post(
          "inventory/admin/products",
          formData
        );
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Product Saved",
            icon: "CheckIcon",
            variant: "success",
            text: "The product has been saved successfully.",
          },
        });
        this.$router.push({ name: "inventory" });
      } catch (error) {
        console.error("Error saving product:", error);
      }
    },

    saveAsDraft() {
      const draft = {
        productName: this.productName,
        product_category_id: this.product_category_id,
        sellingPrice: this.sellingPrice,
        costPrice: this.costPrice,
        quantityInStock: this.quantityInStock,
        warehouse_id: this.warehouse_id,
        discount: this.discount,
        addDiscount: this.addDiscount,
        expiryDate: this.expiryDate,
        addExpiryDate: this.addExpiryDate,
        shortDescription: this.shortDescription,
        longDescription: this.longDescription,
        returnPolicy: this.returnPolicy,
        addReturnPolicy: this.addReturnPolicy,
        dateAdded: this.dateAdded,
        uploadedImages: this.uploadedImages,
      };

      localStorage.setItem("addProductDraft", JSON.stringify(draft));
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Draft Saved",
          icon: "CheckIcon",
          variant: "success",
          text: "Your draft has been saved successfully.",
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>